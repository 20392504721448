import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Layout from '../components/templates/Layout'
import SEO from '../components/simple/SEO'

const Container = styled.div`
  width: 100%;
  padding-top: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLink = styled(Link)`
  padding-top: 20px;
  color: black;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h2>Page Not Found :(</h2>
      <StyledLink to="/">Return home</StyledLink>
    </Container>
  </Layout>
)

export default NotFoundPage
